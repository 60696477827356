import { useAuthService } from '~~/layers/auth/app/composables/services/use-auth-service'

export default defineNuxtRouteMiddleware(async (to) => {
  // TODO: Get this working server side
  if (import.meta.server) {
    return
  }

  const authStore = useAuthStore()
  const {
    fetchProfile,
    hasPreviouslyLoggedIn,
  } = useAuthService()

  // console.log('authStore.isLoggedIn', authStore.isLoggedIn)
  // console.log('authStore.currentUser?.id', authStore.currentUser?.id)
  // console.log('to.path', to.fullPath)

  const authedPaths = ['/admin', '/staff', '/platform']

  if (!authStore.isLoggedIn && hasPreviouslyLoggedIn()) {
    try {
      await fetchProfile()
    } catch {
      authStore.clearCurrentUser()

      // Don't redirect guest users on brochureware pages
      if (authedPaths.includes(to.path)) {
        return navigateTo(`/auth/login?redirect=${to.path}`)
      }
    }
  }

  // Lost guest user
  if (
    (to.path.startsWith('/admin') || to.path.startsWith('/staff') || to.path.startsWith('/platform'))
    && !authStore.isLoggedIn
  ) {
    console.log('Lost guest user')
    return navigateTo(`/auth/login?redirect=${to.path}`)
  }

  // Lost staff member
  if (authStore.isStaffOrganisation && (to.path.startsWith('/admin') || to.path.startsWith('/platform'))) {
    console.log('Lost staff member')
    return navigateTo(authStore.accountPath)
  }

  // Lost app admin
  if (authStore.isTenantOrganisation && (to.path.startsWith('/staff') || to.path.startsWith('/platform'))) {
    console.log('Lost app admin')
    return navigateTo(authStore.accountPath)
  }

  // Lost customer
  if (
    !authStore.isTenantOrganisation
    && !authStore.isStaffOrganisation
    && (to.path.startsWith('/staff') || to.path.startsWith('/admin'))
  ) {
    console.log('Lost customer')
    return navigateTo(authStore.accountPath)
  }
})
