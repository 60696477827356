import type { TenantDomain } from '~~/shared/models/tenant/tenant-domains.model'
import type { TenantSettings } from '~~/shared/models/tenant/tenant-settings.model'
import type { TenantSelect } from '~~/server/database/schema/tenant'
import type { TenantDomainSelect } from '~~/server/database/schema/tenant-domain'
import type { Pagination } from '~~/server/utils/db/pagination'
import { useApi } from '~/composables/api/use-api'
import type { GroupSearchResult } from '~~/server/api/tenant/v1/[tenantId]/search/[searchQuery].get'

export function useTenantService() {
  const { apiStatus, api } = useApi()
  const url = useRequestURL()
  const currentHostname = url.hostname

  async function fetchTenant(tenantId: string) {
    return await api<TenantSelect>(`/tenant/v1/${tenantId}`)
  }

  async function fetchTenantByCurrentHostname(hostname?: string) {
    return await api<TenantSelect>(`/tenant/v1/domain/${hostname || currentHostname}`)
  }

  async function updateSettings(data: TenantSettings) {
    const { tenantId } = useTenant()

    return await api<TenantSelect>(`/tenant/v1/${tenantId}`, {
      method: 'PUT',
      body: data,
    })
  }

  async function fetchTenantDomains(tenantId: string, dataParams: Pagination) {
    return await api<TenantDomainSelect[]>(`/tenant/v1/${tenantId}/domain`, {
      method: 'GET',
      params: dataParams,
    })
  }

  async function createNewDomain(data: TenantDomain) {
    const { tenantId } = useTenant()

    return await api<TenantDomainSelect>(`/tenant/v1/${tenantId}/domain`, {
      method: 'POST',
      body: data,
    })
  }

  async function fetchTenantDomain(domainId: string, tenantId: string) {
    return await api<TenantDomainSelect>(`/tenant/v1/${tenantId}/domain/${domainId}`)
  }

  async function fetchGlobalSearch(searchQuery: string, tenantId: string) {
    return await api<GroupSearchResult[]>(`/tenant/v1/${tenantId}/search/${searchQuery}`)
  }

  return {
    apiStatus,
    updateSettings,
    fetchTenant,
    fetchTenantByCurrentHostname,
    fetchTenantDomains,
    createNewDomain,
    fetchTenantDomain,
    fetchGlobalSearch,
  }
}
