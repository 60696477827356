import { decodeJwt } from 'jose'

import { date } from '~~/shared/date'

// 10 minutes. This cannot be less than 5 minutes as the client will auto refresh if 5 mins or less
export function generateAccessTokenLifespan() {
  const expiresFromNowDate = date.now().plus({ minutes: 10 })

  const expiresFromNowSeconds = 600

  return {
    seconds: expiresFromNowSeconds,
    secondsSinceEpoch: expiresFromNowDate.toSeconds(),
    date: expiresFromNowDate.toJSDate(),
    dateString: expiresFromNowDate.toDbFormat(),
  }
}

// 5 months
export function generateRefreshTokenLifespan(restrictedExpiry: boolean = false) {
  const expiresFromNowDate = restrictedExpiry ? date.now().plus({ hours: 1 }) : date.now().plus({ months: 5 })

  const expiresFromNowSeconds = restrictedExpiry ? 3600 : 13140000

  return {
    seconds: expiresFromNowSeconds,
    secondsSinceEpoch: expiresFromNowDate.toSeconds(),
    date: expiresFromNowDate.toJSDate(),
    dateString: expiresFromNowDate.toDbFormat(),
  }
}

export function getPayloadFromAccessToken(token: string) {
  if (!token) {
    return null
  }

  return decodeJwt(token)
}

export function hasJwtExpired(token: string) {
  if (!token) {
    return true
  }

  const payload = getPayloadFromAccessToken(token)

  if (!payload || !payload.exp) {
    return true
  }

  return Date.now() >= payload.exp * 1000
}

export function isJwtExpiringInNext5Minutes(token: string) {
  if (!token) {
    return true
  }

  const payload = getPayloadFromAccessToken(token)

  if (!payload || !payload.exp) {
    return true
  }

  const fiveMinutesInMs = 300000

  return Date.now() >= payload.exp * 1000 - fiveMinutesInMs
}
