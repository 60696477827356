import { Capacitor } from '@capacitor/core'

import { useApiStatus } from './use-api-status'

import { appVersion } from '~~/shared/version'
import { useAuthService } from '~~/layers/auth/app/composables/services/use-auth-service'

export function useApi() {
  const config = useRuntimeConfig()
  const apiStatus = useApiStatus()
  const router = useRouter()
  const controller = new AbortController()

  const api = async <T>(url: string, options?: any): Promise<T> => {
    const response = await $fetch(url,
      {
        baseURL: config.public.platformApiBaseUrl,
        signal: controller.signal,
        credentials: 'include',
        retry: 1,
        retryStatusCodes: [401],
        ...options,

        async onRequest({ options }) {
          const { tenantId } = useTenant()
          const { $network, $log } = useNuxtApp()

          if ($network?.isMaintenanceModeEnabled.value) {
            console.log('Cancelling API call due to maintenance mode')
            controller.abort()
          }

          options.headers = {
            ...options.headers,
            'Tenant-Id': tenantId,
            'Guest-Id': $log?.getGuestId(),
            'App-Platform': Capacitor.getPlatform(),
            'App-Version': appVersion,
            ...useRequestHeaders(['cookie']),
          }

          apiStatus.value.isLoading = true
          apiStatus.value.response.error = null
          apiStatus.value.response.message = null
          apiStatus.value.response.status = null

          // Check to see if we are online or in maintenance mode
          $network.checkConnection()
        },

        onResponse() {
          apiStatus.value.isLoading = false
        },

        async onResponseError({ response }) {
          const { $log } = useNuxtApp()
          const { refreshTokens, logout, hasPreviouslyLoggedIn } = useAuthService()
          const message = response._data?.message || response._data?.statusMessage || response.statusText
          const responseCode = response.status

          // 401 may return from the /profile endpoint
          if (responseCode === 401 && hasPreviouslyLoggedIn()) {
            try {
              await refreshTokens()
            } catch {
              $log.debug('Failed to refresh tokens in API call, logging out')

              apiStatus.value.isLoading = false

              return logout(router.currentRoute.value.fullPath)
            }
          }

          apiStatus.value.response.message = message

          apiStatus.value.response.error = message

          if (responseCode >= 500) {
            $log.error(`API error: ${response.url}`, message)
          }

          apiStatus.value.isLoading = false
        },
      })

    return response
  }

  return { api, apiStatus }
}
