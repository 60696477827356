import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCallout, LazyCardGroup, LazyCodeGroup, LazyCollapsible, LazyFieldGroup, LazyShortcut, LazyTabs, LazyProseCode, LazyProseCodeButton, LazyProseCodeIcon, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyUButton, LazyProseA, LazyProseBlockquote, LazyProseEm, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Callout", LazyCallout],
["CardGroup", LazyCardGroup],
["CodeGroup", LazyCodeGroup],
["Collapsible", LazyCollapsible],
["FieldGroup", LazyFieldGroup],
["Shortcut", LazyShortcut],
["Tabs", LazyTabs],
["ProseCode", LazyProseCode],
["ProseCodeButton", LazyProseCodeButton],
["ProseCodeIcon", LazyProseCodeIcon],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["UButton", LazyUButton],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseEm", LazyProseEm],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
