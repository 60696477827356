<script lang="ts" setup>
import { useI18n, computed } from '#imports'
import { useNuxtApp } from '#app/nuxt'
import { localeOptions } from '~/utils/locale'

const { $getLocale, $switchLocale } = useI18n()
const { $log } = useNuxtApp()

const emit = defineEmits<{
  change: [value: string]
}>()

const activeLocale = $getLocale()

const activeLocaleOption = computed(() => {
  return localeOptions.find(localeOption => localeOption.value === activeLocale)
})

function changeLocale(localeCode: string) {
  $log.event('locale-switch', localeCode)

  emit('change', localeCode)

  $switchLocale(localeCode)
}
</script>

<template>
  <div>
    <USelectMenu
      :modelValue="activeLocaleOption"
      :options="localeOptions"
      @change="changeLocale($event.value)"
    >
      <template
        v-if="activeLocaleOption"
        #leading
      >
        <UIcon :name="activeLocaleOption.icon" />
      </template>
    </USelectMenu>
  </div>
</template>
