import type { NotificationColor } from '#ui/types'

type NotificationType = 'success' | 'error' | 'info' | 'warn'

interface UseNotifications {
  type: NotificationType
  title: string
  description?: string
  icon?: string
}

interface UseNotificationConfig {
  description?: string
  icon?: string
}

interface TypeConfig {
  icon: string
  color: NotificationColor | undefined
}

export function useNotification() {
  function notify({
    type,
    title,
    description,
    icon,
  }: UseNotifications) {
    const toast = useToast()

    if (!type) {
      type = 'info'
    }

    const typeConfig: Record<NotificationType, TypeConfig> = {
      success: {
        icon: 'i-heroicons-check-circle-solid',
        color: 'green',
      },
      error: {
        icon: 'i-heroicons-x-circle-solid',
        color: 'red',
      },
      info: {
        icon: 'i-heroicons-information-circle-20-solid',
        color: 'blue',
      },
      warn: {
        icon: 'i-lucide-triangle-alert',
        color: 'yellow',
      },
    } as const

    if (type in typeConfig === false) {
      throw new Error(`Invalid notification type: ${type}`)
    }

    console.log({
      title,
      color: typeConfig[type].color,
      icon: icon ?? typeConfig[type].icon,
      description,
    })

    toast.add({
      title,
      color: typeConfig[type].color,
      icon: icon ?? typeConfig[type].icon,
      description,
    })
  }

  function notifySuccess(title: string, config?: UseNotificationConfig) {
    notify({
      type: 'success', title, ...config,
    })
  }

  function notifyError(title: string, config?: UseNotificationConfig) {
    notify({
      type: 'error', title, ...config,
    })
  }

  function notifyInfo(title: string, config?: UseNotificationConfig) {
    notify({
      type: 'info', title, ...config,
    })
  }

  function notifyWarning(title: string, config?: UseNotificationConfig) {
    notify({
      type: 'warn', title, ...config,
    })
  }

  return {
    notify,
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyWarning,
  }
}
