import { generateRefreshTokenLifespan } from '~~/layers/auth/shared/utils/jwt'
import type {
  OrganisationSelect,
  UserOrganisationRoleWithOrganisation,
  UserWithOrganisationRoles,
} from '~~/server/database/schema'
import { cookieEnums } from '~~/shared/constants'

export const useAuthStore = defineStore('auth', () => {
  // const {
  //   public: { mediaBaseUrl },
  // } = useRuntimeConfig()

  const mediaBaseUrl = 'https://media.henrycrooks.com'

  const lastUsedOauthProvider = ref<string | null>(null)
  const isImpersonatingUser = ref<boolean>(false)
  const currentUser = ref<UserWithOrganisationRoles | null>(null)

  // Save last used oauth cookie for 1 year
  const lastUsedOauthProviderCookie = useCookie(cookieEnums.LAST_USED_SOCIAL_PROVIDER, {
    path: '/',
    maxAge: 31536000,
  })

  const currentOrganisationCookie = useCookie(cookieEnums.ORGANISATION, {
    path: '/',
    maxAge: generateRefreshTokenLifespan().seconds,
    expires: generateRefreshTokenLifespan().date,
  })

  const isLoggedIn = computed(() => !!currentUser.value?.id)

  const currentOrganisation = computed<OrganisationSelect | null>(() => {
    return currentOrganisationRole.value?.organisation || null
  })

  const currentOrganisationRole = computed<UserOrganisationRoleWithOrganisation | null>(() => {
    if (!currentUser.value?.organisationRoles?.length || !currentOrganisationCookie.value) {
      return null
    }

    return currentUser.value.organisationRoles.find(
      orgRole => orgRole.organisationId === currentOrganisationCookie.value,
    )
  })

  const userHasPasswordAuth = computed(() => {
    return !!currentUser.value?.authenticationMethods?.some(authMethod => authMethod.provider === 'password')
  })

  const avatarUrl = computed(() => {
    return currentUser.value?.avatar?.path ? `${mediaBaseUrl}/${currentUser.value.avatar.path}` : undefined
  })

  const isStaffOrganisation = computed(() => {
    return currentOrganisation.value?.isStaff === true
  })

  const isTenantOrganisation = computed(() => {
    return currentOrganisation.value?.isTenant === true
  })

  const accountPath = computed(() => {
    if (isStaffOrganisation.value) {
      return '/staff'
    }

    if (isTenantOrganisation.value) {
      return '/admin'
    }

    return '/platform'
  })

  // Load initial cookies into state
  function loadCookies() {
    console.log('Auth store: Loading cookies')

    if (currentOrganisationCookie.value) {
      setCurrentOrganisationId(currentOrganisationCookie.value)
    }

    if (lastUsedOauthProviderCookie.value) {
      setLastUsedOauthProvider(lastUsedOauthProviderCookie.value)
    }
  }

  function setCurrentUser(user: UserWithOrganisationRoles | null) {
    currentUser.value = user
  }

  function clearCurrentUser() {
    console.log('Clearing current user')
    setCurrentUser(null)
    setCurrentOrganisationId(null)
  }

  function setLastUsedOauthProvider(provider: string | null) {
    lastUsedOauthProvider.value = provider
    lastUsedOauthProviderCookie.value = provider
  }

  function setCurrentOrganisationId(organisationId: string | null) {
    console.log('setting current organisation', organisationId)
    currentOrganisationCookie.value = organisationId
  }

  function setImpersonating(isImpersonating: boolean) {
    isImpersonatingUser.value = isImpersonating
  }

  function clearTokens() {
    console.log('CLEARING TOKENS')

    // Delete cookies
    clearCurrentUser()
  }

  return {
    lastUsedOauthProvider,
    currentUser,
    setCurrentUser,
    clearCurrentUser,
    clearTokens,
    userHasPasswordAuth,
    isLoggedIn,
    avatarUrl,
    isStaffOrganisation,
    isTenantOrganisation,
    isImpersonatingUser,
    accountPath,
    setCurrentOrganisationId,
    setLastUsedOauthProvider,
    currentOrganisation,
    currentOrganisationRole,
    setImpersonating,
    currentOrganisationCookie,
    loadCookies,
  }
})
