import { isNoPrefixStrategy, isPrefixStrategy } from "nuxt-i18n-micro-core";
import { defineNuxtPlugin, navigateTo, useRuntimeConfig } from "#app";
import { unref, useRoute, useRouter, useNuxtApp, watch, computed, onUnmounted } from "#imports";
export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const route = useRoute();
  const router = useRouter();
  const i18nConfig = config.public.i18nConfig;
  const normalizeLocales = (locales) => {
    if (Array.isArray(locales)) {
      return locales.reduce((acc, locale) => {
        acc[locale] = {};
        return acc;
      }, {});
    } else if (typeof locales === "object" && locales !== null) {
      return locales;
    }
    return {};
  };
  const handleRedirect = async (to) => {
    const currentLocale = nuxtApp.$getLocale().toString();
    const { name } = to;
    let defaultRouteName = name?.toString().replace("localized-", "").replace(new RegExp(`-${currentLocale}$`), "");
    if (!to.params.locale) {
      if (router.hasRoute(`localized-${to.name?.toString()}-${currentLocale}`)) {
        defaultRouteName = `localized-${to.name?.toString()}-${currentLocale}`;
      } else {
        defaultRouteName = `localized-${to.name?.toString()}`;
      }
      if (!router.hasRoute(defaultRouteName)) {
        return;
      }
      const newParams = { ...to.params };
      if (!isNoPrefixStrategy(i18nConfig.strategy)) {
        newParams.locale = i18nConfig.defaultLocale;
      }
      return navigateTo({ name: defaultRouteName, params: newParams }, { redirectCode: 301, external: true });
    }
  };
  if (import.meta.server) {
    if (isPrefixStrategy(i18nConfig.strategy)) {
      await handleRedirect(route);
    }
  }
  router.beforeEach(async (to, from, next) => {
    if (isPrefixStrategy(i18nConfig.strategy)) {
      await handleRedirect(to);
    }
    if (next) {
      next();
    }
  });
  const defineI18nRoute = async (routeDefinition) => {
    const { $getLocale } = useNuxtApp();
    let currentLocale = computed(() => $getLocale());
    const normalizedLocales = normalizeLocales(routeDefinition.locales);
    const updateTranslations = () => {
      const currentLocaleValue = unref(currentLocale);
      if (currentLocaleValue && Object.values(normalizedLocales).length) {
        if (normalizedLocales[currentLocaleValue]) {
          const translation = normalizedLocales[currentLocaleValue];
          const { $mergeGlobalTranslations } = useNuxtApp();
          if ($mergeGlobalTranslations) {
            $mergeGlobalTranslations(translation);
          }
        }
      }
    };
    updateTranslations();
    if (import.meta.client) {
      let stopWatcher = watch(currentLocale, updateTranslations);
      onUnmounted(() => {
        if (stopWatcher) {
          stopWatcher();
          currentLocale = null;
          stopWatcher = null;
        }
      });
    }
  };
  return {
    provide: {
      defineI18nRoute
    }
  };
});
