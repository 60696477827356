
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as profileVn86HT16gzMeta } from "/home/runner/work/saas/saas/app/pages/admin/account/profile.vue?macro=true";
import { default as securityXU9JSJQqkeMeta } from "/home/runner/work/saas/saas/app/pages/admin/account/security.vue?macro=true";
import { default as accountNYNGwjHJX3Meta } from "/home/runner/work/saas/saas/app/pages/admin/account.vue?macro=true";
import { default as _91slug_933N0rLVl2MCMeta } from "/home/runner/work/saas/saas/layers/content/app/pages/admin/content/[contentType]/[slug].vue?macro=true";
import { default as articlesZT8sIhi3CSMeta } from "/home/runner/work/saas/saas/layers/content/app/pages/admin/content/articles.vue?macro=true";
import { default as _91id_93EQGgsyvGZGMeta } from "/home/runner/work/saas/saas/layers/content/app/pages/admin/content/edit/[id].vue?macro=true";
import { default as indexbviGQu1rAmMeta } from "/home/runner/work/saas/saas/layers/content/app/pages/admin/content/index.vue?macro=true";
import { default as newrHLLJcoQJBMeta } from "/home/runner/work/saas/saas/layers/content/app/pages/admin/content/new.vue?macro=true";
import { default as edituScptczFznMeta } from "/home/runner/work/saas/saas/app/pages/admin/customers/[id]/edit.vue?macro=true";
import { default as logs3EhU5X30dsMeta } from "/home/runner/work/saas/saas/app/pages/admin/customers/[id]/logs.vue?macro=true";
import { default as notificationsW7rhpEYF86Meta } from "/home/runner/work/saas/saas/app/pages/admin/customers/[id]/notifications.vue?macro=true";
import { default as overviewtQCyWhNWnxMeta } from "/home/runner/work/saas/saas/app/pages/admin/customers/[id]/overview.vue?macro=true";
import { default as replaysKVTsc45tkGMeta } from "/home/runner/work/saas/saas/app/pages/admin/customers/[id]/replays.vue?macro=true";
import { default as _91id_93dBIX038KpDMeta } from "/home/runner/work/saas/saas/app/pages/admin/customers/[id].vue?macro=true";
import { default as index8ArhD8Sd6SMeta } from "/home/runner/work/saas/saas/app/pages/admin/customers/index.vue?macro=true";
import { default as indexus7mDki4rTMeta } from "/home/runner/work/saas/saas/app/pages/admin/index.vue?macro=true";
import { default as brandzbi8Ao9hV6Meta } from "/home/runner/work/saas/saas/app/pages/admin/settings/brand.vue?macro=true";
import { default as domainspITpBs5TiKMeta } from "/home/runner/work/saas/saas/app/pages/admin/settings/domains.vue?macro=true";
import { default as notifications7uGzlPLNsFMeta } from "/home/runner/work/saas/saas/app/pages/admin/settings/notifications.vue?macro=true";
import { default as teamnodNAHQBEZMeta } from "/home/runner/work/saas/saas/app/pages/admin/settings/team.vue?macro=true";
import { default as settingsKl8EEugDNWMeta } from "/home/runner/work/saas/saas/app/pages/admin/settings.vue?macro=true";
import { default as biometricslfloThkpdTMeta } from "/home/runner/work/saas/saas/layers/auth/app/pages/auth/biometrics.vue?macro=true";
import { default as forgot_45passwordZU8msMsHIVMeta } from "/home/runner/work/saas/saas/layers/auth/app/pages/auth/forgot-password.vue?macro=true";
import { default as logind9XOUKIKx6Meta } from "/home/runner/work/saas/saas/layers/auth/app/pages/auth/login.vue?macro=true";
import { default as logoutte5lvZxLh0Meta } from "/home/runner/work/saas/saas/layers/auth/app/pages/auth/logout.vue?macro=true";
import { default as registeruQxdjDljyKMeta } from "/home/runner/work/saas/saas/layers/auth/app/pages/auth/register.vue?macro=true";
import { default as _91verificationCode_93Cuwwmv26MPMeta } from "/home/runner/work/saas/saas/layers/auth/app/pages/auth/reset-password/[verificationCode].vue?macro=true";
import { default as indexhYQCjlq4QOMeta } from "/home/runner/work/saas/saas/app/pages/index.vue?macro=true";
import { default as maintenancevkqP0NBFSGMeta } from "/home/runner/work/saas/saas/app/pages/maintenance.vue?macro=true";
import { default as native_45loading3mQDiZYCB6Meta } from "/home/runner/work/saas/saas/app/pages/native-loading.vue?macro=true";
import { default as indexvahH0HqRWgMeta } from "/home/runner/work/saas/saas/app/pages/platform/index.vue?macro=true";
import { default as indexpyVdiOwWLTMeta } from "/home/runner/work/saas/saas/app/pages/staff/index.vue?macro=true";
export default [
  {
    name: "admin-account",
    path: "/admin/account",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/account.vue"),
    children: [
  {
    name: "admin-account-profile",
    path: "profile",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/account/profile.vue")
  },
  {
    name: "admin-account-security",
    path: "security",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/account/security.vue")
  }
]
  },
  {
    name: "admin-content-contentType-slug",
    path: "/admin/content/:contentType()/:slug()",
    component: () => import("/home/runner/work/saas/saas/layers/content/app/pages/admin/content/[contentType]/[slug].vue")
  },
  {
    name: "admin-content-articles",
    path: "/admin/content/articles",
    component: () => import("/home/runner/work/saas/saas/layers/content/app/pages/admin/content/articles.vue")
  },
  {
    name: "admin-content-edit-id",
    path: "/admin/content/edit/:id()",
    component: () => import("/home/runner/work/saas/saas/layers/content/app/pages/admin/content/edit/[id].vue")
  },
  {
    name: "admin-content",
    path: "/admin/content",
    component: () => import("/home/runner/work/saas/saas/layers/content/app/pages/admin/content/index.vue")
  },
  {
    name: "admin-content-new",
    path: "/admin/content/new",
    component: () => import("/home/runner/work/saas/saas/layers/content/app/pages/admin/content/new.vue")
  },
  {
    name: "admin-customers-id",
    path: "/admin/customers/:id()",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/[id].vue"),
    children: [
  {
    name: "admin-customers-id-edit",
    path: "edit",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/[id]/edit.vue")
  },
  {
    name: "admin-customers-id-logs",
    path: "logs",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/[id]/logs.vue")
  },
  {
    name: "admin-customers-id-notifications",
    path: "notifications",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/[id]/notifications.vue")
  },
  {
    name: "admin-customers-id-overview",
    path: "overview",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/[id]/overview.vue")
  },
  {
    name: "admin-customers-id-replays",
    path: "replays",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/[id]/replays.vue")
  }
]
  },
  {
    name: "admin-customers",
    path: "/admin/customers",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/customers/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/index.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/settings.vue"),
    children: [
  {
    name: "admin-settings-brand",
    path: "brand",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/settings/brand.vue")
  },
  {
    name: "admin-settings-domains",
    path: "domains",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/settings/domains.vue")
  },
  {
    name: "admin-settings-notifications",
    path: "notifications",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/settings/notifications.vue")
  },
  {
    name: "admin-settings-team",
    path: "team",
    component: () => import("/home/runner/work/saas/saas/app/pages/admin/settings/team.vue")
  }
]
  },
  {
    name: "auth-biometrics",
    path: "/auth/biometrics",
    component: () => import("/home/runner/work/saas/saas/layers/auth/app/pages/auth/biometrics.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/home/runner/work/saas/saas/layers/auth/app/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/home/runner/work/saas/saas/layers/auth/app/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/home/runner/work/saas/saas/layers/auth/app/pages/auth/logout.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/home/runner/work/saas/saas/layers/auth/app/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password-verificationCode",
    path: "/auth/reset-password/:verificationCode()",
    component: () => import("/home/runner/work/saas/saas/layers/auth/app/pages/auth/reset-password/[verificationCode].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/saas/saas/app/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/home/runner/work/saas/saas/app/pages/maintenance.vue")
  },
  {
    name: "native-loading",
    path: "/native-loading",
    component: () => import("/home/runner/work/saas/saas/app/pages/native-loading.vue")
  },
  {
    name: "platform",
    path: "/platform",
    component: () => import("/home/runner/work/saas/saas/app/pages/platform/index.vue")
  },
  {
    name: "staff",
    path: "/staff",
    component: () => import("/home/runner/work/saas/saas/app/pages/staff/index.vue")
  }
]