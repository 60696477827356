<script lang="ts" setup>
import { provide, queryCollectionNavigation } from '#imports'
import { useAsyncData } from '#app/composables/asyncData'

const { data: navigation } = await useAsyncData('navigation', () => queryCollectionNavigation('content'), {
  default: () => [],
})

provide('navigation', navigation)
</script>

<template>
  <div>
    <BrochurewareHeader />

    <UMain>
      <slot />
    </UMain>

    <BrochurewareFooter />
  </div>
</template>
