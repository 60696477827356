import { isNativePlatform } from '~/utils/native-app/capacitor'

export default defineNuxtPlugin({
  name: 'open-new-window',
  dependsOn: ['logger'],
  setup() {
    const { $log } = useNuxtApp()

    const openNewWindow = async (url: string, openInNativeAppBrowser = false) => {
      // If using native and we want to open with the overlayed in-app browser
      if (openInNativeAppBrowser && isNativePlatform()) {
        const { Browser } = await import('@capacitor/browser')

        $log.debug('Opening native window', url)

        const nativeInAppBrowserWindow = await Browser.open({ url })

        return nativeInAppBrowserWindow
      }

      $log.debug('Opening window', url)

      // If native switch to and open with system browser, otherwise new tab
      const target = isNativePlatform() ? undefined : '_blank'

      window.open(url, target)
    }

    // Inject to global context as $openWindow
    return {
      provide: {
        openNewWindow,
      },
    }
  },
})
