import payload_plugin_qLmFnukI99 from "/home/runner/work/saas/saas/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/saas/saas/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/saas/saas/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/saas/saas/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/saas/saas/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/home/runner/work/saas/saas/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/saas/saas/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_X7whXyrIWR from "/home/runner/work/saas/saas/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/saas/saas/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/saas/saas/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/saas/saas/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/saas/saas/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/home/runner/work/saas/saas/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import _01_device_Lt7w8P0JZO from "/home/runner/work/saas/saas/app/plugins/01.device.ts";
import _02_analytics_SDfF7Fjdkq from "/home/runner/work/saas/saas/app/plugins/02.analytics.ts";
import _03_sentry_gKUTKWI6uw from "/home/runner/work/saas/saas/app/plugins/03.sentry.ts";
import _04_logger_8SDvHieHVt from "/home/runner/work/saas/saas/app/plugins/04.logger.ts";
import _05_network_3ZLye0un5i from "/home/runner/work/saas/saas/app/plugins/05.network.ts";
import error_handler_xwgO4KJDpl from "/home/runner/work/saas/saas/app/plugins/error-handler.ts";
import form_validation_client_wUDTpWtECT from "/home/runner/work/saas/saas/app/plugins/form-validation.client.ts";
import open_new_window_RMpQcEi3dW from "/home/runner/work/saas/saas/app/plugins/open-new-window.ts";
import presets_1aypKNZ222 from "/home/runner/work/saas/saas/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/home/runner/work/saas/saas/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import _01_plugin_YYhmPOJYgJ from "/home/runner/work/saas/saas/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import _02_meta_rNQ7QfGk0T from "/home/runner/work/saas/saas/node_modules/nuxt-i18n-micro/dist/runtime/plugins/02.meta.js";
import _03_define_NVktOUGOO7 from "/home/runner/work/saas/saas/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  _01_device_Lt7w8P0JZO,
  _02_analytics_SDfF7Fjdkq,
  _03_sentry_gKUTKWI6uw,
  _04_logger_8SDvHieHVt,
  _05_network_3ZLye0un5i,
  error_handler_xwgO4KJDpl,
  form_validation_client_wUDTpWtECT,
  open_new_window_RMpQcEi3dW,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  _01_plugin_YYhmPOJYgJ,
  _02_meta_rNQ7QfGk0T,
  _03_define_NVktOUGOO7
]